import React from 'react';
import { Box, Grid, Container, Button } from '@mui/material';
import '../css/InfoSection.css';
// import robBG from '../images/robRemoveBG.png';
import robCert from '../images/robFigmaCert.png';
// import robBW from '../images/robBWpic.jpg';
import robOffice from '../images/robLogoOffice.png';

export default function InfoSection(){
    return (
        <div style={{backgroundColor: 'black', paddingTop: '10vh'}}>
            <Box component='div' className='info-bg'>
                <Container maxWidth='xl'>
            <Grid
                container
                direction='row'
                justifyContent="center"
                alignItems="center"
                spacing={4}
                >
                    <Grid item lg={6} md={6} sm={12}>
                        <img src={robOffice} alt='Turn About Coaching Rob' className='rob-bgimg'/>
                    </Grid>
                    <Grid item lg={6} sm={12}>
                        <p className='info-text'>{`My name is Robert Applegate, I work with up and coming leaders to help them step into their destiny, which will cause them to thrive in life.
                        I’m not perfect and I don’t have all the answers and for the love of God, 
                            I aint gonna be your guru. But I can be your coach, your friend, in the 
                            trenches helping you achieve your goals.`} 
                           <br/>
                           <br/>
                           <br/>
                        

                            
                            
                            {`For ten years I been coaching people on a 
                            personal level in various walks of life, fathers, friends, coworkers, and business 
                            leaders. I have walked through times of personal hell, and I have seen tremendous victory.
                            I love to help people; I love to bless people. If you need a guide in your journey, reach out to me.`}</p>
                    </Grid>
                    <Grid item lg={8} sm={12}>
                        <h1 className='info-text-h1'>{`Pastor`}<br/>{`Father`}<br/>{`Husband`}<br/>{`Life Coach`}</h1>
                    </Grid>
                    <Grid item lg={4} sm={12}>
                        <img src={robCert} alt='Turn About Coaching Rob Certification' className='rob-certimg'/>
                    </Grid>
                    <Grid item>
                        <p className='info-text'>Ten Percent of all profits that come into the business goes to helping orphans, widows and building cities. 
                            Click the button to learn more: <Button variant='outlined' onClick={()=>window.open('https://www.tstamman.com/')}>Link</Button></p>
                    </Grid>
            </Grid>
                </Container>   
            </Box>
        </div>
    )
}