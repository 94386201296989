import React from 'react';
import { Box, Grid, Container } from '@mui/material';
import '../css/AboutSection.css';
// import OptionsSection from './OptionsSection';
import CCPModule from '../modals/CCPModal';
import CDPModal from '../modals/CDPModal';
import RPModal from '../modals/RPModal';
import OTMmodal from '../modals/OTMmodal';


export default function AboutSection(props) {

    return (
        <div style={{backgroundColor: 'black', paddingTop: '10vh'}}>
            <Box component='div' className='about-bg'>
                <Container maxWidth='xl'>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-evenly'
                    alignItems='flex-start'
                    spacing={4}
                    
                    >
                        <Grid item>
                            <div>
                            <p className='about-text' >{`Our life is defined by the decisions we make. 
                            The decisions we make whether wise or foolish determine the results we get in life. 
                            Maybe you have made a few bad turns in your life and feel stuck. Let’s work together 
                            to get you going in the right direction.`}
                            <br/>
                            <br/>

                            {`Do you wrestle inside with an inner frustration knowing 
                            you were created for so much more, but you don’t know how to get there?`}</p>
                      
                                       
                            <h1 className='about-text-h1'style={{textAlign: 'center'}}>{`Turn About Coaching Options`}</h1>
                           
                            </div> 
                           
                   
                           
                           
                        </Grid>
                     
                  
                 


                </Grid>
           


                </Container>
                
            </Box>
            {/* <div style={{position: 'relative', minHeight: '20vh'}}> */}
          
        <Container>
        <div style={{paddingTop: '10vh'}}>

        <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={4}
            >
               
          
                <Grid item md={6} sm={12}>
                    <CCPModule toScroll={props.toScroll}/>  
                </Grid>
                <Grid item md={6} sm={12}>
                    <RPModal toScroll={props.toScroll}/>
                </Grid>
                <Grid item md={6} sm={12}>
                    <CDPModal toScroll={props.toScroll}/> 
                </Grid>
                <Grid item md={6} sm={12}>
                    <OTMmodal toScroll={props.toScroll}/>
                </Grid>


               



        </Grid> 
        </div>
        </Container>
        {/* </div> */}

            
         
        </div>
    )
}