

import React, { useState } from 'react';
import { Box, TextField, Grid, Button, InputLabel, MenuItem, FormControl, Select,Container, Dialog } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import emailjs from '@emailjs/browser';
import '../css/ContactForm.css';
// import SignUp from '../controllers/SignUp';






export default function ContactForm(){
    const [ contactOptions, setContactOptions ] = useState('')
    const [ contactName, setContactName ] = useState(''); 
    const [ contactEmail, setContactEmail ] = useState('');
    const [ contactMessage, setContactMessage ] = useState('');
    const [ open, setOpen ]= useState(false)
    // const [ success, setSuccess ] = useState(false)

    const myClickHandle=()=>{
        setOpen(!open)
    }

   const setOptionHandler=(e)=>{
       setContactOptions(e.target.value)
    }
            
    const setNameHandler=(e)=>{
        setContactName(e.target.value)
 
    }

    const setEmailHandler=(e)=>{
        setContactEmail(e.target.value)

    }

    const setMessageHandler=(e)=>{
        setContactMessage(e.target.value)

    }


    const onSubmitHandler=(e)=>{
        e.preventDefault();


        
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_USER_ID)
        .then((result)=>console.log(result.text))
        .then(()=>setOpen(!open))
        .catch((error)=>console.log(error.text))


        const robFormValues ={
            contactOptions: contactOptions,
            contactName: contactName,
            contactEmail: contactEmail,
            contactMessage: contactMessage,
        }
   
        console.log(robFormValues);
        console.log('worked')
        
        setContactOptions('');
        setContactName('');
        setContactEmail('');
        setContactMessage('');

 
    }
    return (
        <Container maxWidth='xl'>
         
        <Dialog open={open} onClose={myClickHandle}>
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert onClose={myClickHandle} severity="success">
                    {`Thank you! --We will reach out shortly`}
                </Alert>
            </Stack>
      </Dialog>


    
        
        <Box>
        {/* <FormControl> */}
            <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
             
             
                > 
               
                <form onSubmit={onSubmitHandler} style={{width: '100vw'}}>
                    
                    <Grid item lg={12} md={8} sm={12}>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Plans</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='contactOptions'
                              value={contactOptions}
                            label="Plans"
                            onChange={setOptionHandler}
                            >
                            
                            <MenuItem value={`Course Correction Plan`}>Course Correction Plan</MenuItem>
                            <MenuItem value={`Rerouting Plan`}>Rerouting Plan</MenuItem>
                            <MenuItem value={`Change of Destination Plan`}>Change of Destination Plan</MenuItem>
                            <MenuItem value={'Off the Map Plan'}>Off the Map Plan</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                       
                    </Grid>
        
                    <Grid item lg={12} md={8} sm={12}>
                            
                        <TextField
                                // style={{minWidth: '40vw'}}
                                // size='medium'
                                
                                fullWidth
                                required
                                margin='normal'
                                label='name'
                                name='contactName'
                                value={contactName}
                                onChange={setNameHandler}

                                />
                                
                    </Grid> 
                    <Grid item lg={12} md={8} sm={12}>                    
                        <TextField
                                // style={{minWidth: '40vw'}}
                                fullWidth
                                required
                                margin='normal'
                                label='email'
                                name='contactEmail'
                                value={contactEmail}
                                onChange={setEmailHandler}
                                />
                    </Grid> 
                    <Grid item lg={12} md={8} sm={12}>                    
                        <TextField
                                // style={{minWidth: '40vw'}}
                                fullWidth
                                required
                                multiline
                                margin='normal'
                                rows={6}
                                label='message'
                                name='contactMessage'
                                value={contactMessage}
                                onChange={setMessageHandler}
                                />
                    </Grid> 
            <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
             
             
                > 
                <Grid item>
                    <Button style={{width: '20vw'}} variant='contained' size="small" type='submit'>Submit</Button>
                    </Grid>
                    </Grid>
               </form>
              
            </Grid>
            {/* </FormControl> */}
        </Box>
   
     </Container>
    )
}