import React from 'react';
import { Grid, Box } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';





export default function IconSection(){
    return (
        <Box component='div'>
        <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={4}
           
            >
                <Grid item>
                    <LinkedInIcon sx={[{'&:hover': {fontSize: '40px',cursor: 'pointer'},}]} style={{color: 'white'}} fontSize='large'
                    onClick={()=>window.open('https://www.linkedin.com/in/robert-applegate-a31303231/')}/>
                </Grid>
                <Grid item>
                     <TwitterIcon sx={[{'&:hover': {fontSize: '40px',cursor: 'pointer'},}]} style={{color: 'white'}} fontSize='large'
                     onClick={()=>window.open('https://twitter.com/turnaboutcoach')}/>
                </Grid>
                <Grid item>
                    <FacebookIcon sx={[{'&:hover': {fontSize: '40px',cursor: 'pointer'},}]} style={{color: 'white'}} fontSize='large'
                    onClick={()=>window.open('https://www.facebook.com/turnaboutcoaching')}/>
                </Grid>
                <Grid item>
                    <InstagramIcon sx={[{'&:hover': {fontSize: '40px',cursor: 'pointer'},}]} style={{color: 'white'}} fontSize='large'
                    onClick={()=>window.open('https://www.instagram.com/turnaboutcoaching/')}/>
                </Grid>
                    

              

        </Grid>
        </Box>
    )
}