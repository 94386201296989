import React, { createRef } from 'react';
import { AppBar, Toolbar, CssBaseline, useScrollTrigger, Slide } from '@mui/material';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import InfoSection from './components/InfoSection';
import ContactSection from './components/ContactSection';
import FooterSection from './components/FooterSection';
import logo from './images/robLogo.png';

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction='down' in={trigger}>
      { children }
    </Slide>
  )
};


function App(props){
  const scrollDiv = createRef();
  const smoothScroll = ()=>{
    scrollDiv.current.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar style={{backgroundColor: '#5A6050', height: '100px'}}>
          <Toolbar>
            <img src={logo} height='40' style={{marginTop: '20px', maxWidth: '50vw'}} alt='Turn About Coaching logo'/>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <HeroSection/>
      <AboutSection toScroll={smoothScroll}/>
      <InfoSection/>
      <ContactSection scrollDiv={scrollDiv}/>
      <FooterSection/>

    </>
  )
}

export default App