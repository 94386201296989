import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography, Container, Grid,Card, CardActions, CardContent, CardMedia,  } from '@mui/material';





export default function OTMmodal(props){
    const [ open, setOpen ] = useState(false);

const handleSubmit= async()=>{
    await setOpen(prev=>!prev)
   props.toScroll();   
}

    const handleClickOpen=()=>{
        setOpen(prev=>!prev)
    }

    const handleClickClose=()=>{
        setOpen(prev=>!prev)
    }
    return (
         <>
         
                <Card sx={{ maxWidth: 500 }}>
                <CardMedia
                        component="img"
                        height="300"
                        // image='https://images.unsplash.com/photo-1509239129736-8c395375220d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60'
                        image="https://images.unsplash.com/photo-1623598017567-ad9a94c2d761?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                        // image="https://images.unsplash.com/photo-1583679614333-35d8e036ad9a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=988&q=80"
                        alt="Off the Map Plan"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" textAlign='center'>
                        Off the Map Plan
                        </Typography>
                  
                    </CardContent>
                    <CardActions>
                    <Button fullWidth variant='contained' size="small" onClick={handleClickOpen}>Open</Button>

                    </CardActions>
                    </Card>
                    <Dialog 
                        open={open} 
                        onClose={handleClickClose}
                        PaperProps={{
                            style: {
                                backgroundColor: 'black',
                                color: 'white',
                                opacity: '0.90',
                                border: '2px',
                            },
                            
                        }}
                        // fullWidth
                        maxWidth="md"
                    >
         
                    <DialogContent>
                    
                        
                    <h1 className='modal-text-h1'>Off the Map Plan</h1>
                    <hr/>
                    <p className='modal-text-p'> 20-week premium coaching package</p>
                    <Container>
                    <Grid 
                        container
                        direction='row'
                        alignItems='center'
                        justifyContent='center'
                        >
                    


                    
                    <Grid item>
                        
                        <ul>
                            
                            <li>First session complementary</li>
                            <li>Last session complementary</li> 
                            <li>1:15 hr coaching session</li>
                            <li>Online or in person</li>
                            <li>Recording of each week’s session</li>
                            <li>Accountability call (30min) every week </li>
                            <li>Accountability text every week </li>
                            <li>Encouragement text sent every week</li>
                            <li>Recomended resources</li> 

                            
                        </ul>
    

                        
                    </Grid>
         

                    <Grid item>
            
                        <ul>
                            <li>Cost: $150 per session</li> 
                            <li>Payments or Full pay accepted</li>
                            <li>Discount for plan paid up front</li>
                        
                            
                        </ul> 
                    </Grid>
       


                    <Grid item>
                                
                                <ul>
                                    <li>Full plan price: $3000</li> 
                                    <li>Pay in full discount: $2800</li>
                            
                                </ul> 
                    </Grid>
            
                    </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                {/* <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={2}>

                    <Grid item lg={12}> */}

                  
                    <Button variant='contained' size="small" onClick={handleClickOpen}>Close</Button>
                    <Button variant='contained' size="large" onClick={handleSubmit}>Sign Up</Button>
                    {/* </Grid>
                </Grid> */}

                </DialogActions>
            </Dialog>
               
                </>
    )
}