import React from 'react';
import { Grid, Container, Link, Box } from '@mui/material';
import logo from '../images/robLogo.png';
import '../css/FooterSection.css';
import IconSection from './IconSection';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationCityIcon from '@mui/icons-material/LocationCity';


const FooterSection=()=>{
    return (
        <Box component='div'>
        <div className='footer-bg'>
               <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
              
                
                >
                  <Grid item style={{backgroundColor: '#5A6050', width: '100vw'}}>
                <img src={logo} alt='Turn About Coaching logo' className='footer-logo'/>

                </Grid>
                </Grid>
                <br/>
            <Container maxWidth='xl'>
            <Grid
                container
                direction='row'
                justifyContent='space-evenly'
                alignItems='center'
                
                >
                {/* <Grid item style={{backgroundColor: '#5A6050', width: '100vw'}}>
                <img src={logo} alt='Turn About Coaching logo' className='footer-logo'/>

                </Grid> */}
                        <Grid item lg={6} sm={12}>
                            {/* <img src={logo} alt='Turn Around Coaching logo' className='footer-logo'/> */}
                            <h1 className='footer-text-h1'><LocationCityIcon style={{color: 'white'}} fontSize='large'/><span> <strong>{`510 E locust St, Unit 104, Des Moines IA 50309`}</strong></span></h1>
                            <h1 className='footer-text-h1'><EmailIcon style={{color: 'white'}} fontSize='large'/><span> rapplegate@turnaboutcoaching.com</span></h1>
                            <h1 className='footer-text-h1'><LocalPhoneIcon style={{color: 'white'}} fontSize='large'/><span> (570) 290-4089</span></h1>

                        </Grid>
        
                    <Grid item lg={6} sm={12}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d605.2282597515327!2d-93.61099783956611!3d41.59003626787606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87ee99b1f3cb1d3f%3A0x2dca59cc7b0d8c0f!2s510%20E%20Locust%20St%20Suite%20104%2C%20Des%20Moines%2C%20IA%2050309!5e0!3m2!1sen!2sus!4v1646607989556!5m2!1sen!2sus" width="60%" height="auto" style={{border: '0'}} allowFullScreen="" title='Turn Around Coaching Location'loading="lazy"></iframe>

                            <IconSection/>
                        </Grid>

                   
                    <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='flex-end'
              
              >
            <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='baseline'
                
                >

             

   
            </Grid>
                <Grid item>
                <p style={{color: 'white'}}>Website built by: <span><Link href="https://www.linkedin.com/in/colbyholt/" underline="hover">
                  {'CoHDev'}
                </Link></span></p>
                </Grid>

          </Grid>
   
            </Grid>
           
        </Container>
        </div>
        </Box>
    )
}

export default FooterSection