import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography, Container, Grid, Card, CardActions, CardContent, CardMedia,  } from '@mui/material';




export default function CDPModal(props){
    const [ open, setOpen ] = useState(false);

//     const handleSubmit=()=>{
//         setOpen(prev=>!prev)
//        setTimeout(() => { props.toScroll()  }, 200);
       
//    }
const handleSubmit= async()=>{
    await setOpen(prev=>!prev)
     props.toScroll();
    
}

    const handleClickOpen=()=>{
        setOpen(prev=>!prev)
    }

    const handleClickClose=()=>{
        setOpen(prev=>!prev)
    }
    return (
        <>
                <Card sx={{ maxWidth: 500 }}>
                <CardMedia
                        component="img"
                        height="300"
                        // width='auto'
                        image='https://images.unsplash.com/photo-1509239129736-8c395375220d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60'
                        alt="Change of Direction Plan"
                    />
           
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" textAlign='center'>
                        Change of Destination Plan
                        </Typography>
                    
                    </CardContent>
                    <CardActions>
                    <Button fullWidth variant='contained' size="small" onClick={handleClickOpen}>Open</Button>

                    </CardActions>
                    </Card>
                    <Dialog 
                open={open} 
                onClose={handleClickClose}
                PaperProps={{
                    style: {
                        backgroundColor: 'black',
                        color: 'white',
                        opacity: '0.90',
                        border: '2px',
                    },
                    
                }}
                // fullWidth
                maxWidth="md"
            >
         


                    <DialogContent>
                    
                        
                    <h1 className='modal-text-h1'>Change of Destination Plan</h1>
                    <hr/>
                    <p className='modal-text-p'> 10-week coaching package</p>
                    <Container>
                    <Grid 
                        container
                        direction='row'
                        alignItems='center'
                        justifyContent='center'
                        >
                    



                    <Grid item>
                        
                        <ul>
                            <li>First session free</li> 
                            <li>One session a week (1 hr) each</li>
                            <li>Online or in person</li>
                            <li>Recording of each week’s session</li>
                            <li>Accountability call (20 min) every week</li> 
                            <li>Encouragement text every week</li>
                            <li>At the end of the 10 weeks / free follow up session (1hr)</li>
                            
                        </ul>
    

                        
                    </Grid>
         
        

                    <Grid item>
            
                        <ul>
                            <li>Cost: $75 per session</li> 
                            <li>Pay by session or plan</li>
                            <li>Discount for plan paid up front</li>
                        
                            
                        </ul> 
                    </Grid>
       
 


                    <Grid item>
                                
                                <ul>
                                    <li>Discount Full Plan Price : $659</li> 
                                    <li>Payment Price: $750 ($75 Per session) </li>
                            
                                </ul> 
                    </Grid>
                    <Grid item>
                                
                                <ul>
                                    <li>Only 12 coaching spots available</li> 
                                    
                            
                                </ul> 
                    </Grid>
            
                    </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                {/* <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'>

                    <Grid item> */}

                  
                    <Button variant='contained' size="small" onClick={handleClickOpen}>Close</Button>
                    <Button variant='contained' size="large" onClick={handleSubmit}>Sign Up</Button>
                    {/* </Grid> */}
                {/* </Grid> */}

                </DialogActions>
            </Dialog>

   </>
    )
}