import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Container ,Typography,Grid,Card, CardActions, CardContent, CardMedia } from '@mui/material';
import '../css/ModalSyles.css';
// import SignUp from '../controllers/SignUp';


export default function CCPModule(props){

    const [ open, setOpen ] = useState(false);
    // const [ option, setOption ] = useState('test');



    const handleSubmit= async()=>{
        await setOpen(prev=>!prev)
        props.toScroll();  
    }

    const handleClickOpen=()=>{
        setOpen(prev=>!prev)
    }

    const handleClickClose=()=>{
        setOpen(prev=>!prev)
    }
    return (
     <>
        <Card sx={{ maxWidth: 500 }}>
            <CardMedia
                component="img"
                height="300"
                image="https://images.unsplash.com/photo-1483213097419-365e22f0f258?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                alt="Course Correction Plan"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div" textAlign='center'>
                    Course Correction Plan
                </Typography>
    
            </CardContent>
            <CardActions>
                <Button fullWidth variant='contained' size="small" onClick={handleClickOpen}>Open</Button>
 
            </CardActions>
            </Card>
            <Dialog 
                open={open} 
                onClose={handleClickClose}
                PaperProps={{
                    style: {
                        backgroundColor: 'black',
                        color: 'white',
                        opacity: '0.90',
                        border: '2px',
                    },
                    
                }}
                // fullWidth
                maxWidth="md"
            >
                <DialogContent>
                 
                       
                    <h1 className='modal-text-h1'>Course Correction Plan</h1>
                    <hr/>
                    <p className='modal-text-p'>5-week coaching package</p>
                    <Container>
                    <Grid 
                        container
                        direction='row'
                        alignItems='center'
                        justifyContent='center'
                        >
                     
                    <Grid item>
                        
                         <ul>
                            <li>First session free</li> 
                            <li>One session a week (15 min) each</li>
                            <li>Online or in person</li>
                            <li>Recording of each week’s session</li>
                            <li>Accountability text every week</li> 
                            
                        </ul>
    

                        
                    </Grid>
                    <Grid item>
            
                        <ul>
                            <li>Cost: $30 per session</li> 
                            <li>Pay by session or plan</li>
                            <li>Discount for whole plan paid up front</li>
                        
                            
                        </ul> 
                    </Grid>

                    <Grid item>
                                
                                <ul>
                                    <li>Discount Full Plan Price : $125</li> 
                                    <li>Payment Price: $150 ($30 Per session) </li>
                            
                                </ul> 
                    </Grid>
              
                   </Grid>
                   </Container>
                </DialogContent>
                <DialogActions>
                    {/* <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'>

                    <Grid item> */}

                   
                    <Button variant='contained' size="small" onClick={handleClickOpen}>Close</Button>
                    <Button variant='contained' size="large" onClick={handleSubmit}>Sign Up</Button>
                    {/* </Grid>
                </Grid> */}

                </DialogActions>
            </Dialog>
       </>
    )
}