import React from 'react';
import { Grid, Container, Box } from '@mui/material';
import '../css/HeroSection.css';
// import logo from '../images/robLogo.png';
import hero from '../images/robFigmaHero2.jpg'
import logo2 from '../images/robLogo2.png'

export default function HeroSection(){


    return (
      
        <Box component='div' style={{backgroundImage:`linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5) ),url(${hero})`, backgroundSize:'cover', backgroundPosition:'center'}}sx={{
            width: '100%',
            height: { lg: '100vh',sm:'100vh',xs:'50vh', md:'100vh' },
          
          }}>
        <div>
       
            
            <Container>
                <Grid
                    container
                    direction='row'
                    justifyContent='center'>
                        <Grid item>
                            <img src={logo2} className='hero-logo' alt='Turn Around Coaching logo'/>
                   
                         
                        </Grid>

                </Grid>
           </Container>

        </div>
   </Box>  

 
    )
}