import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography, Container, Grid,Card, CardActions, CardContent, CardMedia,  } from '@mui/material';


export default function RPModal(props){
    const [ open, setOpen ] = useState(false);
//     const handleSubmit=()=>{
//         setOpen(prev=>!prev)
//        setTimeout(() => { props.toScroll()  }, 200);
       
//    }
const handleSubmit= async()=>{
    await setOpen(prev=>!prev)
   props.toScroll();
   


    
}

    const handleClickOpen=()=>{
        setOpen(prev=>!prev)
    }

    const handleClickClose=()=>{
        setOpen(prev=>!prev)
    }
    return (
         <>
         
                <Card sx={{ maxWidth: 500 }}>
                <CardMedia
                        component="img"
                        height="300"
                        image='https://images.unsplash.com/photo-1470472304068-4398a9daab00?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8ZGlyZWN0aW9ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60'
                        alt="Rerouting Plan"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" textAlign='center'>
                        Rerouting Plan
                        </Typography>
                  
                    </CardContent>
                    <CardActions>
                    <Button fullWidth variant='contained' size="small" onClick={handleClickOpen}>Open</Button>

                    </CardActions>
                    </Card>
                    <Dialog 
                        open={open} 
                        onClose={handleClickClose}
                        PaperProps={{
                            style: {
                                backgroundColor: 'black',
                                color: 'white',
                                opacity: '0.90',
                                border: '2px',
                            },
                            
                        }}
                        // fullWidth
                        maxWidth="md"
                    >
         
                    <DialogContent>
                    
                        
                    <h1 className='modal-text-h1'>Rerouting Plan</h1>
                    <hr/>
                    <p className='modal-text-p'> 7-week coaching package</p>
                    <Container>
                    <Grid 
                        container
                        direction='row'
                        alignItems='center'
                        justifyContent='center'
                        >
                    


                    
                    <Grid item>
                        
                        <ul>
                            <li>First session free</li> 
                            <li>One session a week (30 min) each</li>
                            <li>Online or in person</li>
                            <li>Recording of each week’s session</li>
                            <li>Accountability text and call (10min) every week </li> 
                            
                        </ul>
    

                        
                    </Grid>
         

                    <Grid item>
            
                        <ul>
                            <li>Cost: $50 per session</li> 
                            <li>Pay by session or plan</li>
                            <li>Discount for plan paid up front</li>
                        
                            
                        </ul> 
                    </Grid>
       


                    <Grid item>
                                
                                <ul>
                                    <li>Discount Full Plan Price : $300</li> 
                                    <li>Payment Price: $350 ($50 Per session) </li>
                            
                                </ul> 
                    </Grid>
            
                    </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                {/* <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={2}>

                    <Grid item lg={12}> */}

                  
                    <Button variant='contained' size="small" onClick={handleClickOpen}>Close</Button>
                    <Button variant='contained' size="large" onClick={handleSubmit}>Sign Up</Button>
                    {/* </Grid>
                </Grid> */}

                </DialogActions>
            </Dialog>
               
                </>
    )
}